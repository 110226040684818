
.spai-option {
  --#{$prefix}dropdown-item-hover-bg: #{$dropdown-link-hover-color};

  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 0.25rem 1rem;
  height: auto;
  line-height: 1.5rem;

  &:hover {
    &:not([disabled]) {
      background-color: var(--#{$prefix}dropdown-item-hover-bg);
    }
  }
}
