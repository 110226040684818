html {
  --mat-menu-container-color: #25283D;
  --mat-menu-item-hover-state-layer-color: #6271c6;
  --mat-option-hover-state-layer-color: #6271c6;

  --mat-table-header-container-height: 48px;

  --mat-table-row-item-outline-color: #464f5b;

  --mat-table-row-item-container-height: 48px;
  --mat-table-row-item-label-text-color: #bfbfbf;

}

.mat-mdc-row:nth-child(2n) {
  background-color: rgb(47, 50, 77);
}