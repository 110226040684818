$app-primary-color: #88cd41;
$col-primary-selection: #40addd;
$col-map-bg :#D8D8D8;
$col-grey: #cccccc;
$col-default: #9599a7;
$col-white: #ffffff;

// @mixin setTitleBgColor($color) {
//   .app-popup__header {
//     background-color: $color;

//     &::before {
//       border-bottom-color: $color;
//     }
//   }
// }

body.app-popup--open {
  // +media-query($bp-screen-min-xs) {
  //   height: 100%;
  //   overflow: hidden;
  // }
}

.app-popup {
  position: absolute; //: false auto false 0;
  top: calc(100% + 20px);
  box-shadow: 4px 4px 18px $col-default;
  z-index: 1000005;
  background-color: #ffffff;
  pointer-events: auto;
  margin-bottom: 10px;
  cursor: default;
  line-height: 1.3em;
  // prevents issue when text goes beyond the popup area
  white-space: normal;

  &--overlay {
    position: fixed;
    top: 0;
    left: 0;
    // fixed: 0 false false 0;
    size: 100%;
    background-color: rgba(0, 0, 0, 0.68);
    z-index: 119999;
    box-shadow: none;

    .app-popup__container {
      box-shadow: 0 0 20px rgba(0,0,0,0.5);
    }
  }

  &--fixed {
    .app-popup__container {
position: fixed;
top:0;
      bottom: auto;
      margin: auto;
      width: 600px;
      max-width: 90vw;
      max-height: 90vh;
      background-color: white;
      overflow: auto;

      // TODO: Think of something better
      margin-top: 5vh;

      // +media-query($bp-screen-max-xs) {
      //   margin-top: auto;
      //   bottom: 0;
      //   min-width: auto;
      //   max-height: none;
      // }
    }
  }

  &--large {
    .app-popup__container {
      width: 100%;
      max-width: 826px;
      min-width: 320px;
    }

    .app-popup__header {
      font-weight: 700;
      font-size: 24px;
      padding-top: 21px;
      padding-bottom: 20px;

      .app-popup__btn-left {
        top: 6px;
        left: 8px;
      }
      .app-popup__btn-right {
        top: 6px;
        right: 8px;
      }
    }
  }

  &--medium {
    .app-popup__container {
      width: 100%;
      max-width: 700px;
    }
  }

  .app-popup__content {
    color: var(--spai-primary);
    padding: 10px;
    text-transform: initial;
  }

  &__header{
    padding: 15px 35px 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    min-width: 320px;
    cursor: default;
    user-select: none;
    color: $col-white;
    background-color: $col-primary-selection;
    text-transform: uppercase;
    text-align: center;

    &::before {
      position: absolute;
      content: '';
      top: -20px;
      left: 5px;
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 20px solid $col-primary-selection;
    }

    &::after {
      position: absolute;
      content: '';
      top: -20px;
      left: 5px;
      width: 100px;
      height: 20px;
    }
  }

  &--fixed {
    .app-popup__header {
      min-width: 0;
    }
  }

  &--without-corner {
    .app-popup__header::before {
      display: none;
    }
  }

  // todo: .app-popup__btn-left -> .btn.btn--icon
  &__btn-left {
    position: absolute;
top: 0;
bottom: 0;
    absolute: 0 false false 0;
    cursor: pointer;
    size: 40px;
    display: block;
    line-height: 14px;
    font-size: 30px;

    .app-icon {
      fill: #ffffff;
position: absolute;
      margin: auto;
    }
  }

  &__btn-right {
    @extend .app-popup__btn-left;
    left: auto;
    right: 0;
  }

  &__footer {
    border-top: 1px solid $col-grey;
    line-height: 0;
    display: flex;

    .btn {
      // font-bold();

      &:last-of-type {
        margin-left: auto;

        &:not(.btn--loading) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    .btn--flat {
      flex-shrink: 0;
    }
  }

  &__content {
    padding: 10px;
  }

  &--vertical-reverse {
    bottom: 20px;

    .app-popup__header::before {
      top: auto;
      bottom: -20px;
      border-top: 20px solid white;
      border-bottom: none;
    }
  }

  &--horizontal-reverse {
    left: auto;
    right: 10px;

    .app-popup__header::before {
      left: auto;
      right: 5px;
    }
  }

  &--danger {
    // setTitleBgColor($col-caution);
  }

  &--success {
    // setTitleBgColor($col-success);
  }

  &--warning {
    // setTitleBgColor($col-warning);
  }

  &--prev-next-mode {
    .app-popup__header {
      // font-normal();
      text-transform: none;

      strong {
        text-transform: uppercase;
      }
    }

    // +media-query($bp-screen-max-xs) {
    //   .item-list {
    //     flex-wrap: wrap;
    //   }

    //   .item-list__general-information {
    //     width: calc('100% - 94px');
    //   }

    //   .item-list__description {
    //     width: 100%;
    //     order: 1;
    //     margin-top: 5px;
    //     margin-bottom: 5px;
    //   }
    // }

    .app-popup__btn-left[name="cross2"] {
      display: none;

      // +media-query($bp-screen-max-xs) {
      //   display: block;
      //   left: 40px;
      // }
    }
  }
}

.app-popup__edit {
  .app-icon--location-icon{
    width: 70px;
    height: 70px;
  }

  .map-container{
    height: 310px;
    width: 100%;
    background-color: $col-map-bg;
    display: flex;

    &__clear-map-block{
      width: 150px;
      margin: auto;
    }

    &__clear-map-block-text {
      width: 150px;
      line-height: 20px;
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__clear-map-block-icon {
      width: 70px;
      margin-left: 55px;
      height: 70px;
    }
  }

  .two-columns {
    display: flex;
    width: 580px;

    .column {
      width: 50%;

      &:first-child {
        padding-right: 20px;
      }
    }

    // +media-query($bp-screen-max-xs) {
    //   width: 100%;
    //   flex-wrap: wrap;

    //   .column {
    //     width: 100%;

    //     &:first-child {
    //       padding-right: 0;
    //       padding-bottom: 10px;
    //     }
    //   }
    // }
  }

  .form__select,
  .age-requirements {
    margin-bottom: 10px;
  }

  .form__input,
  .form__select .form-control {
    padding: 10px;
    min-height: 43px;
  }

  .form__textarea {
    min-height: 150px;
    padding: 10px;

    &__wrap {
      position: relative;
    }

    &__counter {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 14px;
      color: #86CF33;

      &--exceeds {
        color: #8f2035;
      }
    }

    &::placeholder {
      font-family: sans-serif;
      font-size: 13px;
      font-weight: 200;
      font-style: italic;
    }
  }

  .age-requirements {
    display: flex;

    .btn {
      display: inline-block;
      height: 43px;
      width: 43px;
      padding: 0;
      font-size: 16px;
      font-weight: bold;
      line-height: 1;
      border: 1px solid #444c7d;
      text-align: center;

      &.active {
        background-color: #444c7d;
        color: white;
      }
    }

    &__switch {
      display: flex;
      margin-right: 8px;

      .btn {
        width: 60px;
      }
    }

    &__inputs {
      display: flex;

      &--hidden {
        visibility: hidden;
      }

      .form__input {
        width: 56px;
        text-align: center;

        &--disabled {
          pointer-events: none;
          background-color: #ececec;
        }
      }

      .btn {
        font-size: 35px;
        font-weight: 200;
        background-color: #444c7d;
        color: white;
      }
    }
  }

  .ui-select-choices-row {
    & > a {
      white-space: normal;
      font-size: 14px;
      line-height: 16px;

      .app-icon {
        fill: #ffffff;
        vertical-align: top;
        margin-top: 4px;
      }
    }

    &.active {
      .skill-item--internal {
        background-color: #428bca;
      }
    }
  }

  .form__select .dropdown-menu > li .ui-select-choices-row > .dropdown-item {
    padding: 0;
  }

  .ui-select-multiple {
    min-height: auto;
    height: auto;
    max-width: 300px;
    padding: 10px;
    line-height: 1;

    .skill-item {
      pointer-events: none;
      padding: 5px;
      display: block;

      &--internal {
        background-color: #777;
        color: #ffffff;
      }
    }

    .ui-select-match-item {
      position: relative;
      float: left;
      margin-right: 5px;
      padding: 0;
      background-color: transparent;
      border: 1px solid #a7a7a7;
      font-size: 14px;
      line-height: 18px;
      color: #333;
      border-radius: 3px;
      text-transform: none;
      -webkit-appearance: none;

      .close {
        position: absolute;
        right: 5px;
        top: 2px;
      }

      .skill-item {
        display: block;
        padding: 2px 20px 2px 5px;

        &--internal {
          background-color: #777777;
          color: white;
        }
      }

      .app-icon {
        fill: #ffffff;
        margin-top: 2px;
        vertical-align: top;
      }
    }

    input.ui-select-search {
      height: 26px;
      min-height: 26px;
      font-size: 13px;
      font-style: italic;
      font-weight: 200;
    }
  }

  .ui-select-match .close {
    float: right;
    width: 12px;
    color: transparent;

    &::before {
      content: '✕';
      font-size: 16px;
      color: #a7a7a7;
    }
  }
}

.popup-message {
  padding: 20px;

  &__header {
    font-size: 24px;
    text-align: center;
    margin-bottom: 0;
  }

  &__content{
    max-height: calc(80vh - 100px);
    overflow: auto;
    white-space: pre-line;
  }
}
