$col-info: #fffa99;
$col-info-origin: #40addd;
$col-primary: #2c3250;

.spai-notifications-wrapper {
  position: fixed;
  right: 8px;
  top: 4px;
  z-index: 10000;
  // transform: translateX(-50%);
  width: 360px;
  max-width: 100%;
  // max-width: 100%;
  user-select: none;
}

.single-notification {
  position: relative;
  padding-bottom: 10px;
  transform-origin: 50% 0;
}

.single-notification__inner {
  size: 100% 80px;
  width: 100%;
  height: 80px;
  background: $col-info;
  color: $col-primary;
  fill: $col-primary;
  text-transform: uppercase;
  display: table;
}

.single-notification__icon-cell {
  display: table-cell;
  vertical-align: top;
  text-align: right;
  width: 62px;
  padding-top: 24px;

  .svgi {
    fill: $col-primary;
    height: 30px;
    width: 30px;
  }
}

.single-notification__text-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 15px 8px 15px;
}
