html {
  --mdc-plain-tooltip-supporting-text-color: #42486d;
  --mdc-plain-tooltip-container-color: #e9e0e4;
}

.mat-mdc-tooltip-surface {
  padding: 0.7rem !important;
}

.header-tooltip.mat-mdc-tooltip {
  //transform: translateY(-110%) !important;
  //margin-left: 19rem !important;
}

.mdc-tooltip--multiline .mat-mdc-tooltip-surface {
  text-align: center !important;
}
