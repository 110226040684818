
@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito/Nunito-Light.eot");
  src: local("Nunito Light"), local("Nunito-Light"),
    url("../../assets/fonts/Nunito/Nunito-Light.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Nunito/Nunito-Light.woff") format("woff"), url("../../assets/fonts/Nunito/Nunito-Light.ttf") format("truetype"),
    url("../../assets/fonts/Nunito/Nunito-Light.svg#Roboto") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito/Nunito-Regular.eot");
  src: local("Nunito Regular"), local("Nunito-Regular"),
    url("../../assets/fonts/Nunito/Nunito-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Nunito/Nunito-Regular.woff") format("woff"),
    url("../../assets/fonts/Nunito/Nunito-Regular.ttf") format("truetype"),
    url("../../assets/fonts/Nunito/Nunito-Regular.svg#Roboto") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito/Nunito-SemiBold.eot");
  src: local("Nunito SemiBold"), local("Nunito-SemiBold"),
    url("../../assets/fonts/Nunito/Nunito-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Nunito/Nunito-SemiBold.woff") format("woff"),
    url("../../assets/fonts/Nunito/Nunito-SemiBold.ttf") format("truetype"),
    url("../../assets/fonts/Nunito/Nunito-SemiBold.svg#Roboto") format("svg");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/Nunito/Nunito-Bold.eot");
  src: local("Nunito Bold"), local("Nunito-Bold"),
    url("../../assets/fonts/Nunito/Nunito-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Nunito/Nunito-Bold.woff") format("woff"), url("../../assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype"),
    url("../../assets/fonts/Nunito/Nunito-Bold.svg#Roboto") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}



$spotlight-font-family-sans-serif: Nunito, sans-serif;
$spotlight-font-family-base: $spotlight-font-family-sans-serif;

//Bootstrap fonts overwride
$font-family-sans-serif: $spotlight-font-family-sans-serif;
$font-family-base: $spotlight-font-family-base;
