$padinator-heigh: 40px;

.spai-grid-view {
  display: block;
  height: 100%;
}

.spai-table-container {
  overflow: auto;
  height: calc(100% - $padinator-heigh);
}

.spai-grid-warapper {
  height: 100%;
}

.mat-paginator {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  flex: 1
}

table {
  width: 100%;
}

.spai-mat-table {
  thead {
    tr {
      background-color: var(--spai-table-header-bg);

      th {
        color: white;
      }
    }
  }

  .mat-column-actions {
    width: 40px;
  }
}

.spai-grid-selection-column {
  width: 60px;

  mat-checkbox {
    line-height: 16px;

    * {
      line-height: 16px;
      margin: 0;
    }
  }

}

mat-checkbox {
  line-height: 16px;

  label {
    margin-bottom: 0;
  }

  * {
    line-height: 16px;
    margin: 0;
  }
}