.spai-icon,
spai-icon {
  display: inline-block;
  vertical-align: middle;
  line-height: 1;

  svg {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }
}
