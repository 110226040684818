html {
  --mdc-dialog-container-color: #25283D;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-subhead-color: #fafafafa;
  --mdc-dialog-supporting-text-color:#fafafafa;
  
  
  --mat-menu-item-hover-state-layer-color: #6271c6;
  --mat-option-hover-state-layer-color: #6271c6;
}

