ngx-mat-timepicker {
  .mat-icon-button {
    background-color: transparent;
    color: #ffffff;
  }
}

.mat-calendar-period-button,
.mat-calendar-previous-button,
.mat-calendar-next-button,
.actions .mat-button-base {
  color: #ffffff;
  background-color: var(--spai-primary);
  border-color: var(--spai-primary);
}
