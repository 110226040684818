$prefix: 'spai-';

$light-focused: rgba(white, 0.12);


$white: #ffffff !default;
$black: #000000 !default;


$spai-primary: #5C6BC0;
$spai-secondary: #434B55;
$spai-success: #0acf97;
$spai-warning: #ffbc00;
$spai-danger: #fa5c7c!default;

$spai-dark-body-bg: #17171D;
$spai-dark-body-color: white;
$spai-dark-card-bg: #25283D;
$spai-dark-border: #434b55;

$font-size-base:              1rem !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.25 !default;


//input variables

$input-padding-y: .375rem !default;
$input-padding-x: .75rem !default;

$input-bg: $spai-dark-card-bg !default;
$input-focus-bg: $spai-dark-body-bg !default;
$input-color: $spai-dark-body-color !default;
$input-focus-color: $input-color !default;
$input-placeholder-color: #6c757d !default;
$input-focus-box-shadow: rgb(92 107 192 / 25%) 0px 0px 0px 0.25rem;
$input-box-shadow: inset 0 1px 2px rgba($black, .075) !default;


$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

// end input variables

// scss-docs-start dropdown-variables

$dropdown-link-hover-color: $spai-primary !default;


// scss-docs-end dropdown-variables


// btn-variable
//
$btn-padding-x: 0!default;
$btn-padding-y: 16px!default;
$btn-line-height: 2rem!default;
$btn-border-radius: 0.25rem!default;
$btn-font-family: $font-family-base !default;
$btn-font-size: inherit!default;

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;


// end
