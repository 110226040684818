.d-flex {
  display: flex;
}

.d-flex-row {
  display: flex;
  gap: 0.25rem;

  &>* {
    flex: 1 1 50%;
  }
}

.w-100 {
  width: 100% !important;
}
