html,
body {
  height: 100%;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $spotlight-font-family-sans-serif;

  scrollbar-width: thin;
  font-size: 1rem;
  line-height: 1.5rem;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $spotlight-font-family-base;
  background-color: $spai-dark-body-bg;
  color: #ffffff;
}

body[data-theme="dark"] {
  --#{$prefix}primary: #{$spai-primary};
  --#{$prefix}body-color: #{$spai-dark-body-color};
  --#{$prefix}body-bg: #{$spai-dark-body-bg};
  --#{$prefix}border-color: #{$spai-dark-border};
  --#{$prefix}panel-bg: #{$spai-dark-card-bg};
  --#{$prefix}card-bg: #{$spai-dark-card-bg};
  --#{$prefix}link-color: #a6aedc;
  --#{$prefix}link-hover-color: #ffbc00;
  --#{$prefix}border: 1px solid var(--spai-border-color);
  --#{$prefix}border-radius: 4px;

  --#{$prefix}card-bd: #{$spai-dark-card-bg};

  --#{$prefix}table-header-bg: #42486d;
}

a {
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  color: var(--spai-link-color);

  &:hover {
    color: var(--spai-link-hover-color);
  }
}

ul {
  list-style: none;
}

header.spai-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99;
}

main {
  // margin-top: 64px;
}

.spai-main-content-wrapper {
  margin-left: 240px;
  display: flex;
  flex: 1;
  flex-basis: 1e-9px;
  height: calc(100vh - 64px);

  .spai-main-content {
    flex: 1;
    flex-basis: 1e-9px;
    padding: 1rem;
    max-width: 100%;
  }
}

.spai-nav {
  // top: 64px;
  z-index: 98;
  width: 240px;
  background-color: $spai-dark-card-bg;
  height: 100%;
}

.flex-row {
  display: flex;
  position: relative;
  gap: 2rem;
  flex-wrap: wrap;
}

.flex-new-row {
  flex-basis: 100%;
}

.nested-key {
  display: inline-block;
  margin-top: 0;
  margin-bottom: 1.2rem;
}

.nested-margin {
  margin-left: 2rem;
}

spai-tenant-config {
  .mat-mdc-form-field {
    margin-top: 0.2rem;
    width: 18rem;
    height: 3.3rem;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

///table-toolbar
///
.spai-toolbar-wrapper {
  margin-bottom: 0.5rem;
}

.spai-toolbar-container {
  display: flex;
  padding: 0;
  user-select: none;
}

///
/// end table-toolbar
