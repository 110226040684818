@import 'options.scss';

.spai-select {
  --#{$prefix}select-bg: var(--spai-input-bg);

  display: inline-block;
  outline: none;
  width: 100%;
  display: inline-block;
  border: var(--spai-border);
  background-color: var(--#{$prefix}select-bg);
  border-radius: var(--spai-border-radius);
  padding: .375rem .75rem;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;

  &.form-control:focus,
  &:focus {
    background-color: var(--spai-body-bg);
    color: var(--spai-input-color);
    border-color: var(--spai-border-color);
    box-shadow: rgb(92 107 192 / 25%) 0px 0px 0px 0.25rem;
  }

  &.spai-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: .875rem;
    border-radius: 0.25rem;

    .spai-select-trigger {
      height: 1.313rem;
    }
  }


  .spai-select-trigger {
    height: 1.5rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  .spai-select-placeholder {
    color: var(--spai-placeholder-color);
    user-select: none;
  }

  .spai-select-value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
  }
}

@mixin user-select($value) {
  -webkit-user-select: $value;
  user-select: $value;
}

.spai-select {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  outline: none;
}

.spai-select-trigger {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  .spai-select-disabled & {
    @include user-select(none);
    cursor: default;
  }
}


.spai-select-clear-wrapper {
  width: 24px;
  height: 31px;
  color: #ffffff;
  .spai-select-clear {
    display: flex;
  }
}

.spai-select-value {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spai-select-min-line:empty::before {
  content: ' ';
  white-space: pre;
  width: 1px;
  display: inline-block;

  // Prevents some browsers from rendering the element in high contrast mode. Use `visibility`
  // instead of `opacity` since VoiceOver + Chrome still reads out the space with the latter.
  visibility: hidden;
}


.spai-select-panel-wrap {
  flex-basis: 100%;
  margin-top: 4px;
  margin-bottom: 4px;

  .spai-select-panel {
    min-width: calc(100% + 24px);
    min-width: 112px;
    max-width: 280px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 5px;
    padding-bottom: 5px;
    max-height: 256px;
    min-width: 100%;
    background: var(--spai-card-bg);
    border: 1px solid var(--spai-border-color);
    border-radius: var(--spai-border-radius);
    outline: 0;
  }

}


.spai-select-arrow-wrapper {
  height: 16px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;

  .spai-select-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    border-top: 5px solid #ffffff;
    margin: 0 4px;
  }
}

.spai-select-clear {
  $btn-close-color:            #ffffff !default;
  $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;

  background: $btn-close-bg;



  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em;
  // color: #000;
  border: 0;
  border-radius: 0.375rem;
  opacity: .5;
}










