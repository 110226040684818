.spai-form-control {
  &.spai-control-invalid {
    border-color: $spai-danger;
    z-index: 4;
  }
}

.spai-control-invalid ~ .spai-control-error {
  display: block;
  position: absolute;
  top: 34px;
  text-align: end;
}

.spai-control-error {
  display: none;
  color: $spai-danger;
  width: 100%;
  font-size: 0.875em;
}
