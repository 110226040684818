@import 'forms_validation';

:root {
  --spai-input-color: #{$input-color};
}

.spai-form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  background-color: $input-bg;
  color: $input-color;
  line-height: 1.5;
  border: var(--spai-border);
  border-radius: var(--spai-border-radius);
  appearance: none; // Fix appearance for date inputs in Safari
  background-clip: padding-box;
  box-sizing: border-box;
  box-shadow: $input-box-shadow;

  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
  }

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }
}

label {
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  display: inline-block;

}

.spai-label {
  box-sizing: border-box;
  margin-bottom: 0.5rem;
}


.spai-form-control-wrapper {
  margin-bottom: 1rem;

  &.time-select {
    .mat-icon-button {
      position: absolute;
      right: 4px;
      top: -2px;
    }
  }
}


/// temp styles

.input-group {
  position: relative;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  // transition: background-color 600000s 0s, color 600000s 0s;
  background-color: rgb(232, 240, 254) !important;
}


.spai-control-container {
  margin-bottom: 1.5rem;
  display: block;
}


.spai-control-row {
  display: flex;
  width: 100%;
  gap: 0.8rem;

  .spai-form-control-wrapper {
    flex: 1 1 auto;
    margin-bottom: 0;
  }
}

.spai-input-group.spai-input-group-password {
  color: var(--spai-input-color);
  border: var(--spai-border);
  border-radius: var(--spai-border-radius);
  display: inline-block;
  background-color: #25283D;
  background-size: 0% 100%;
  transition: background-size 0.2s ease;
  width: 100%;

  &.spai-focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
  }

  input {
    color: var(--spai-input-color);
    background: transparent;
    border: none;
    max-width: 100%;
    padding: .375rem .75rem;
    padding-right: 0;
    margin: 0;
    width: calc(100% - 34px);
    border-radius: 0;
    border-top-left-radius: var(--spai-border-radius);
    border-bottom-left-radius: var(--spai-border-radius);
    box-shadow: none;
    background: none;
    outline: none;
    line-height: 1.5;

    display: inline-block;
  }
}


.spai-input-group.spai-input-group-password {
  display: flex;
}

ngs-code-editor {
  .monaco-editor.editor {
    min-height: 100%;
  }
}

.spai-field-disabled {
  pointer-events: none;

  .mat-mdc-text-field-wrapper {
    background-color: #2f324d;
  }

  .form-control,
  .spai-form-control {
    background-color: #2f324d;
  }
}
