.spai-nav {
  position: fixed;
  left: 0;

  .spai-nav-list,
  .spai-sub-nav {
    list-style: none
  }

  .spai-sub-nav {
    padding-inline-start: 1rem;
  }

  .spai-nav-item {
    padding: 0.5rem 0.25rem;

    &:hover {
      .spai-nav-link {
        color: #bccee4
      }
    }
  }

  .spai-nav-link {
    color: #aab8c5;
    text-decoration: none;
  }
}
