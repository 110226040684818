// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();


// Note: Color palettes are generated from primary: #5C6BC0
$_palettes: (
  primary: (0: #000000,
    10: #00105b,
    20: #15267b,
    25: #233386,
    30: #2f3f92,
    35: #3c4b9e,
    40: #4858ab,
    50: #6271c6,
    60: #7c8be2,
    70: #96a5ff,
    80: #bac3ff,
    90: #dee0ff,
    95: #f0efff,
    98: #fbf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (0: #000000,
    10: #181a2c,
    20: #2c2f42,
    25: #383a4d,
    30: #434659,
    35: #4f5165,
    40: #5b5d72,
    50: #73768b,
    60: #8d8fa6,
    70: #a8aac1,
    80: #c3c5dd,
    90: #dfe1f9,
    95: #f0efff,
    98: #fbf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  tertiary: (0: #000000,
    10: #2d1228,
    20: #44263e,
    25: #503149,
    30: #5d3c55,
    35: #6a4861,
    40: #76536d,
    50: #916c86,
    60: #ac85a1,
    70: #c99fbc,
    80: #e5bad8,
    90: #ffd7f1,
    95: #ffecf6,
    98: #fff7f9,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #26283d,
    20: #313031,
    25: #3C3B3C,
    30: #474647,
    35: #535253,
    40: #5F5E5F,
    50: #787678,
    60: #929091,
    70: #ADAAAB,
    80: #C9C6C7,
    90: #E5E1E3,
    95: #F4F0F1,
    98: #FCF8F9,
    99: #FFFBFF,
    100: #ffffff,
    4: #0e0e11,
    6: #26283d,
    12: #1f1f23,
    17: #2a2a2d,
    22: #353438,
    24: #39393c,
    87: #dcd9de,
    92: #eae7ec,
    94: #f0edf1,
    96: #f6f2f7,
  ),
  neutral-variant: (0: #000000,
    10: #1a1b23,
    20: #2f3038,
    25: #3a3b43,
    30: #46464f,
    35: #51525b,
    40: #5d5e67,
    50: #767680,
    60: #90909a,
    70: #abaab4,
    80: #c6c5d0,
    90: #e3e1ec,
    95: #f1effa,
    98: #fbf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$spai-dark-palette: (
    0: #000000,
    10: #00105b,
    20: #15267b,
    25: #233386,
    30: #2f3f92,
    35: #3c4b9e,
    40: #4858ab,
    50: #6271c6,
    60: #7c8be2,
    70: #96a5ff,
    80: #bac3ff,
    90: #dee0ff,
    95: #f0efff,
    98: #fbf8ff,
    99: #fefbff,
    100: #ffffff,
  secondary: (0: #000000,
    10: #181a2c,
    20: #2c2f42,
    25: #383a4d,
    30: #434659,
    35: #4f5165,
    40: #5b5d72,
    50: #73768b,
    60: #8d8fa6,
    70: #a8aac1,
    80: #c3c5dd,
    90: #dfe1f9,
    95: #f0efff,
    98: #fbf8ff,
    99: #fefbff,
    100: #ffffff,),
  neutral: (
    4: #0a0b0c,
    6: #101013,
    12: #1e1f23,
    17: #292a2d,
    22: #343438,
    24: #39393d,
    87: #dbdade,
    92: #e9e8ec,
    94: #efedf1,
    96: #f5f3f7,
  ),
  neutral-variant: (0: #000000,
    10: #1a1b23,
    20: #2f3038,
    25: #3a3b43,
    30: #46464f,
    35: #51525b,
    40: #5d5e67,
    50: #767680,
    60: #90909a,
    70: #abaab4,
    80: #c6c5d0,
    90: #e3e1ec,
    95: #f1effa,
    98: #fbf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  )
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes, neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$light-theme: mat.define-theme((color: (theme-type: light,
        primary: $_primary,
        tertiary: $_tertiary,
        use-system-variables: true,
      ),
      typography: (use-system-variables: true,
      ),
      density: (scale: -5)));
$dark-theme: mat.define-theme((color: (theme-type: dark,
        primary: $_primary,
        tertiary: $_tertiary,
        use-system-variables: true,
      ),
      typography: (use-system-variables: true,
      ),
      density: (scale: -5)));

html {
  // @include mat.core();
  // @include mat.core-theme($dark-theme);
  @include mat.all-component-themes($dark-theme);
  @include mat.system-level-colors($dark-theme);
  @include mat.system-level-typography($dark-theme);
}



