html {
  // --mdc-outlined-text-field-outline-color: #434b55;
  // --mdc-outlined-text-field-hover-outline-color: #434b55;
  // --mdc-outlined-text-field-focus-outline-color: #434b55;

  // --mdc-outlined-text-field-error-outline-color: #fa5c7c;
  // --mdc-outlined-text-field-error-hover-outline-color: #fa5c7c;
  // --mdc-outlined-text-field-error-focus-outline-color: #fa5c7c;
}

mat-form-field {
  width: 100%;
}

.mat-focused .mat-mdc-text-field-wrapper {
  background-color: #17171d;
}

div.mat-mdc-form-field-flex {
  height: 100%;
  align-items: center;
}
