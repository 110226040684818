@import 'styles/index.scss';
@import './app/select/select.scss';

@import './app/shared/notifications/notification.component';
@import './app/shared/popup/popup';


html {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

spai-app-root {
  display: block;
  left: 0;
  min-height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}


.delete-variable-wrapper {
  display: inline-block;
  flex: 0;
  height: 27px;
  margin-top: 36px;
}


ngx-mat-calendar,
.time-container{
  background-color: var(--spai-panel-bg);
}