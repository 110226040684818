.spai-button {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-font-family: #{$btn-font-family};
  // --#{$prefix}btn-font-weight: #{$btn-font-weight};
  --#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$spai-dark-body-color};
  --#{$prefix}btn-bg: transparent;
  // --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-hover-border-color: transparent;
  // scss-docs-end btn-css-vars

  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: var(--#{$prefix}btn-line-height);
  padding: var(--#{$prefix}btn-padding-x) var(--#{$prefix}btn-padding-y);
  border-radius: var(--#{$prefix}btn-border-radius);
  overflow: visible;
  background: var(--#{$prefix}btn-bg);

  &::-moz-focus-inner {
    border: 0;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--#{$prefix}btn-hover-color);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }

  &.loading {
    cursor: wait;
    pointer-events: none;
    opacity: 0.7;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #2b3048;
    background-image: linear-gradient(to right,
        #dee2e6 8%,
        #49507b 18%,
        #dee2e6 33%);
    background-size: 800px 104px;
    position: relative;
    size: 100px 18px;
  }
}

.spai-button,
.spai-button-icon,
.spai-button-outline {
  color: inherit;
}

.spai-button-outline {
  --#{$prefix}btn-hover-bg: var(--spai-primary);

  border: 1px solid var(--spai-primary);

  &:active,
  &:target {
    --#{$prefix}btn-bg: #4e5ba3;
  }

  &:hover {
    background-color: #2c3250;
    border: 1px solid var(--spai-primary);
    svg {
      color: #ffffff;
    }
  }

  &.spai-button-icon {
    &:hover {
      svg {
        color: #ffffff;
      }
    }
  }
}

.spai-button-solid {
  --#{$prefix}btn-bg: #{$spai-primary};
  --#{$prefix}btn-hover-bg: #4e5ba3;

  outline: none;
  border: none;
}

.spai-button-icon {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  position: relative;
  box-sizing: border-box;
  border: none;

  &:hover {
    svg {
      color: #a6aedc;
    }
  }
}

.spai-button-block {
  margin-top: 0.25rem;
  display: flex;
  gap: 0.5rem;
  line-height: 1;
  justify-content: flex-end;
}

.mat-mdc-icon-button.input-button-close {
  position: absolute;
  top: 0.3rem;
  right: -0.5rem;
}

.spai-button-add {
  border: 1px solid var(--spai-primary);

  &:focus {
    background-color: transparent;
    border: 1px solid var(--spai-primary);
  }

  &:hover {
    background-color: #2c3250;
    border: 1px solid var(--spai-primary);
  }
}
