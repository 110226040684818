html {
  --mat-expansion-header-indicator-color: #ffffff;
  --mat-expansion-container-shape: 4px;
}

.mat-expansion-panel-header,
.mat-expansion-panel-header.mat-expanded:focus,
.mat-expansion-panel-header.mat-expanded:hover {
  background-color: #5C6BC0 !important;
}

.mat-expansion-indicator svg {
  fill: #ffffff;
}

div.mat-expansion-panel-body {
  padding: 1.5rem;
}

.spai-nav-item-collapsible {
  padding: 0.5rem 0.25rem;

  &:hover {
    .spai-nav-link-header {
      color: #bccee4
    }
  }

  .mat-expansion-panel {
    box-shadow: none !important;
  }

  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background-color: transparent !important;
    padding-left: 0;
    align-items: start;
    height: 2rem !important;
    color: #bccee4
  }

  .mat-expansion-panel-body {
    padding: 0;
  }
}
